import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Chip } from "@mui/material";
import { stringToColor } from "../helpers/stringtocolor.helper";

export function VideoCard(props) {
	return (
		props.video && (
			<Card sx={{ minWidth: 275 }}>
				<CardContent>
					<Typography variant="h5" component="div" gutterBottom>
						{props.video.title}
					</Typography>

					<Typography sx={{ fontSize: 14 }} gutterBottom>
						{props.video.description}
					</Typography>
					<div className="tagContainer">
						{props.video.tags.map((tag) => {
							const bgColor = stringToColor(tag);
							return (
								<Chip
									size="small"
									style={{ backgroundColor: bgColor, color: "white" }}
									key={tag}
									label={tag}
									variant="filled"
								/>
							);
						})}
					</div>
				</CardContent>
				<CardActions>
					<Button size="small">Bekijk video</Button>
				</CardActions>
			</Card>
		)
	);
}
