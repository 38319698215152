import { Container, Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Divider } from "@mui/material";
import tricks from "../data/tricks.json";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
function compareNumbers(a, b) {
	return b.week - a.week;
}
export function TricksPage() {
	tricks.sort(compareNumbers);
	return (
		<Container sx={{ mt: 1 }}>
			{tricks &&
				tricks.map((week) => {
					return (
						<Accordion elevation={10} defaultExpanded={week.week === 11}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<Typography variant="h6">{`Week ${week.week}`}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container>
									{week.tricks &&
										week.tricks.map((trick) => {
											return (
												<>
													<FormControlLabel control={<Checkbox />} label={trick} />
													<Divider />
												</>
											);
										})}
								</Grid>
							</AccordionDetails>
						</Accordion>
					);
				})}
		</Container>
	);
}
