import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HomePage } from "./pages/home.page";
import { BottomAppBar } from "./components/appbar.comp";
import { SchedulePage } from "./pages/schedule.page";
import { TricksPage } from "./pages/tricks.page";
import { VideosPage } from "./pages/videos.page";

export default function App() {
	return (
		<BrowserRouter>
			<BottomAppBar />
			<Routes>
				<Route exact path="/" element={<HomePage />} />
				<Route exact path="/schedule" element={<SchedulePage />} />
				<Route exact path="/training" element={<TricksPage />} />
				<Route exact path="/videos" element={<VideosPage />} />
			</Routes>
		</BrowserRouter>
	);
}
