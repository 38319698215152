import { Container } from "@mui/material";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import timeGridPlugin from "@fullcalendar/timegrid";
import events from "../data/events.json";

export function SchedulePage() {
	return (
		<Container>
			<FullCalendar
				plugins={[timeGridPlugin]}
				initialView="timeGridDay"
				events={events}
				headerToolbar={false}
				allDaySlot={false}
				nowIndicator={true}
				slotLabelFormat={{
					hour: "numeric",
					minute: "2-digit",
					meridiem: false,
					hour12: false,
				}}
				dayHeaderContent={false}
				dayHeaders={false}
				expandRows={true}
				contentHeight={"auto"}
				slotMinTime={"08:00:00"}
			/>
		</Container>
	);
}
