import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import TodayIcon from "@mui/icons-material/Today";
import ChecklistIcon from "@mui/icons-material/Checklist";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export function BottomAppBar() {
	const navigate = useNavigate();
	return (
		<AppBar className="appBar" color="primary" sx={{ top: "auto", bottom: 0 }}>
			<Toolbar>
				<Button onClick={() => navigate("/")} className="appBarButton">
					<HomeIcon fontSize="large" />
				</Button>
				<Button onClick={() => navigate("/schedule")} className="appBarButton">
					<TodayIcon fontSize="large" />
				</Button>

				<Button onClick={() => navigate("/training")} className="appBarButton">
					<ChecklistIcon fontSize="large" />
				</Button>

				<Button disabled className="appBarButton">
					<OndemandVideoIcon disabled fontSize="large" />
				</Button>
			</Toolbar>
		</AppBar>
	);
}
