import { Container, Button, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import TodayIcon from "@mui/icons-material/Today";
import ChecklistIcon from "@mui/icons-material/Checklist";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import { useNavigate } from "react-router-dom";

export function HomePage() {
	const navigate = useNavigate();
	return (
		<Container>
			<div className="homeContainer">
				<Card variant="outlined" className="buttonContainer">
					<Button
						onClick={() => navigate("schedule")}
						variant="outlined"
						sx={{ width: "100%", height: "100%", flexDirection: "column" }}
						className="buttonContainer"
					>
						<TodayIcon sx={{ width: "100%", height: "100%", mt: ".5em" }} />
						<Typography style={{ height: "5svh", marginBottom: "1em", marginTop: "-.75em" }}>
							Schema
						</Typography>
					</Button>
				</Card>
				<Card onClick={() => navigate("training")} variant="outlined" className="buttonContainer">
					<Button variant="outlined" sx={{ width: "100%", height: "100%", flexDirection: "column" }}>
						<ChecklistIcon sx={{ width: "100%", height: "100%", mt: ".5em" }} />
						<Typography style={{ height: "5svh", marginBottom: "1em", marginTop: "-.75em" }}>
							Training
						</Typography>
					</Button>
				</Card>
				<Card disabled variant="outlined" className="buttonContainer">
					<Button disabled variant="outlined" sx={{ width: "100%", height: "100%", flexDirection: "column" }}>
						<OndemandVideoIcon sx={{ width: "100%", height: "100%", mt: ".5em" }} />
						<Typography style={{ height: "5svh", marginBottom: "1em", marginTop: "-.75em" }}>
							Videos
						</Typography>
					</Button>
				</Card>
			</div>
		</Container>
	);
}
