import { Container, Grid } from "@mui/material";
import videos from "../data/videos.json";
import { VideoCard } from "../components/videocard.comp";

export function VideosPage() {
	return (
		<Container sx={{ mt: 1 }}>
			<Grid container spacing={2}>
				{videos &&
					videos.map((video) => {
						return (
							<Grid key={video.title} item xs={12}>
								<VideoCard video={video} />
							</Grid>
						);
					})}
			</Grid>
		</Container>
	);
}
